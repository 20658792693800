import { Grid, useTheme } from '@mui/material';
import { ComparedIndex, MapEnum, MapStateKey, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { findCenterOfGeometry, getBoundsForPoints, getSunPosition } from 'common/utils/util';
import MapViewMarker from 'components/MapViewMarker';
import Minimap from 'components/Minimap';
import configs from 'constants/config';
import { DEFAULT_VIEWPORT, QUERY_KEY } from 'constants/constants';
import { useLabelAnalytic } from 'hooks/map-view/useLabelAnalytic';
import useMapSetting from 'hooks/map-view/useMapSetting';
import { useViewAnalyticLayer } from 'hooks/map-view/useViewAnalyticLayer';
import { get, isArray, isEmpty } from 'lodash';
import polylabel from 'polylabel';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGL, { Layer, NavigationControl, Source, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { sendGetAllLocation, sendGetLocationDetails } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateAllLocationCropType } from 'store/slices/clientSlice';
import { clearChlorophyllAnalytics } from 'store/slices/map-view/chlorophyllAnalytics';
import { clearCo2SequestrationAnalytics } from 'store/slices/map-view/co2SequestrationAnalytics';
import { clearGapAnalytics } from 'store/slices/map-view/gapAnalytics';
import { clearPlantHealthOilPailmAnalytics } from 'store/slices/map-view/plantHealthOilPailmAnalytics';
import { clearTreeHeightAnalytics } from 'store/slices/map-view/treeHeightAnalytics';
import { clearTreeTiltFallenAnalytics } from 'store/slices/map-view/treeTiltFallenAnalytics';
import { clearWeedInvasionAnalytics } from 'store/slices/map-view/weedInvasionAnalytics';
import { changeAbleTransport, changeMapStyle, changeViewPort, mapPopupSelector } from 'store/slices/mapPopupSlice';
import {
  changeClickedStandPointId,
  changeMapViewState,
  changePointCenters,
  clearData,
  clearState,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import { changeFlyToState, rightBarSelector } from 'store/slices/rightBarSlice';
import { LayerTypeEnum } from '../../common/defines/constants';
import { useDrawArcLayer } from '../../hooks/map-view/useDrawArcLayer';
import {
  changeCircumferenceEditedDetail,
  clearCircumferenceAnalytics,
} from '../../store/slices/map-view/circumferenceAnalytics';
import {
  changeDrawCrownAreaState,
  clearCrownAreaAnalytics,
  crownAreaAnalyticsSelector,
} from '../../store/slices/map-view/crownAreaAnalytics';
import { clearPlantStressAnalytics } from '../../store/slices/map-view/plantStressAnalytics';
import {
  changeDataPointClicked,
  changePointStandCount,
  standCountAnalyticSelector,
} from '../../store/slices/map-view/standCountAnalytics';
import { clearVigorAnalytics } from '../../store/slices/map-view/vigorAnalytics';
import { clearWaterUptakeAnalytics } from '../../store/slices/map-view/waterUptakeAnalytics';
import DrawPolygonStandCount from './DrawPolygonStandCount';
import { MarkerPoint } from './MarkerPoint';
import PolygonLayer from './PolygonLayer';
import DrawCrownAreaShape from './RightBar/AnalyticsTab/Edit/crown-area-edit/DrawCrownAreaShape';
import ShowOrthoImageInMapPopup from './RightBar/AnalyticsTab/TemporalModal/ShowOrthoImageInMapPopup';
import DrawIssueShape from './RightBar/IssuesTab/components/DrawIssueShape';
import DropMarker from './RightBar/IssuesTab/components/DropMarker';
import ShowIssueShape from './RightBar/IssuesTab/components/show-issue-shape';
import StandCountBoundary from './StandCountBoundary';
import { ToolMapView } from './ToolMapView';
import LinesVectorLayer from './Vector/LinesVectorLayer';
import StreamlineOrderLayer from './Vector/StreamlineOrderLayer';
import VectorContourLayer, { IHoverVectorInfo } from './Vector/VectorContourLayer';

export const MapView = ({
  showAnalytics,
  openAnalytic,
  navbarMapWidth,
  analyticPanelWidth,
  mapStateKey,
  comparedIndex,
}: {
  navbarMapWidth: string | number;
  analyticPanelWidth: '0px' | '455px' | 0;
  mapStateKey: MapStateKey;
  showAnalytics: boolean;
  openAnalytic: Dispatch<SetStateAction<boolean>>;
  comparedIndex?: ComparedIndex;
}) => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const mapRef = useRef<any>(null);
  const containerMapRef = useRef<any>(null);

  const [hoverInfo, setHoverInfo] = useState<any>(null);
  const [hoverVectorInfo, setHoverVectorInfo] = useState<IHoverVectorInfo>();

  const {
    analyticName,
    issuesTab: {
      flyTo,
      drawIssueShape: { isLockMap },
    },
  } = useAppSelector(rightBarSelector);
  const {
    drawCrownArea: { isLockMap: isLockMapEditCrownArea },
  } = useAppSelector(crownAreaAnalyticsSelector);
  const mouseRef = useRef<{ clientX: number; clientY: number }>({ clientX: 0, clientY: 0 });

  const theme = useTheme();
  const [mapStyle, setMapStyle] = useState(
    theme.palette.mode === 'dark' ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10'
  );
  const isMap3D = useMemo(() => mapStyle === 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y', [mapStyle]);

  const { isShowPopupAdd, isPolygonMode, isPointerMode } = useAppSelector(standCountAnalyticSelector);
  const {
    levelId,
    cropType,
    isLastLevelId,
    layerTypeList,
    layerTypeCurrentList,
    mapViewState,
    isStandPointEditMode,
    isInEditTab,
  } = useAppSelector(mapViewSelector);

  const {
    listDateInfo,
    viewPort: mapPopupViewPort,
    mapStyle: mapPopupStyle,
    ableToTransport,
  } = useAppSelector(mapPopupSelector);

  //Layer analytics
  const { polygonAnalytics } = useViewAnalyticLayer({ mapRef, isMap3D });
  const { arcLayer } = useDrawArcLayer({ mapRef, isMap3D });
  const { interactiveLayerIds } = useLabelAnalytic();
  const settingsMap = useMapSetting();

  const [viewport, setViewport] = useState({
    ...DEFAULT_VIEWPORT,
    pitch: 0,
    bearing: 0,
  });

  const {
    data: dataMapView,
    refetch: refetchDataMapView,
    remove: removeDataMapView,
  } = useQuery(
    [QUERY_KEY.DATA_MAP_KEY, clientId, cropType, levelId],
    () => sendGetAllLocation(clientId ?? null, cropType, levelId ?? 'null'),
    {
      enabled: false,
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    dispatch(updateAllLocationCropType(dataMapView?.data));
  }, [dataMapView, dispatch]);

  // move to exact location after click issue search
  useEffect(() => {
    if (!flyTo.done) {
      mapRef.current?.flyTo({
        center: findCenterOfGeometry(flyTo.geometry),
        zoom: mapViewState.zoom,
        essential: true,
        speed: 1.0,
        curve: 1,
      });
      dispatch(changeFlyToState({ done: true }));
    }
  }, [dispatch, flyTo, mapViewState.zoom]);

  // move map view of the second popup map follow first map
  const {
    data: { longitude, latitude, ...props },
    comparedIndex: storeCI,
  } = mapPopupViewPort;

  useEffect(() => {
    if (
      ableToTransport &&
      ((storeCI === ComparedIndex.FIRST && comparedIndex === ComparedIndex.SECOND) ||
        (storeCI === ComparedIndex.SECOND && comparedIndex === ComparedIndex.FIRST))
    ) {
      mapRef.current?.flyTo({
        center: [longitude, latitude],
        essential: true,
        ...props,
      });
      dispatch(changeAbleTransport(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longitude, latitude]);

  // set map style in mapPopup mode
  useEffect(() => {
    if (mapStateKey === MapStateKey.MAP_POPUP && mapPopupStyle) {
      setMapStyle(mapPopupStyle);
    }
  }, [mapPopupStyle, mapStateKey]);

  // reset lock map when not in edit mode of crown area
  useEffect(() => {
    if (!isInEditTab) {
      dispatch(changeDrawCrownAreaState({ isLockMap: false }));
    }
  }, [dispatch, isInEditTab]);

  const {
    data: dataMapViewLastLevel,
    refetch: refetchDataMapViewLastLevel,
    remove: removeDataMapViewLastLevel,
  } = useQuery(
    [`${QUERY_KEY.DATA_MAP_KEY}_CLIENT`, clientId, cropType, levelId],
    () => sendGetLocationDetails(levelId || 'null'),
    {
      enabled: false,
      keepPreviousData: false,
    }
  );

  // ticket 946 common vector always on top
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (map) {
        const vectorLayerList = ['streamline_order', 'vector_contour', 'elevator-number-layer'];
        vectorLayerList.forEach((item) => {
          if (map.getLayer(item)) map.moveLayer(item, null);
        });
      }
    }
  }, [polygonAnalytics]);

  useEffect(() => {
    if (!clientId) {
      removeDataMapView();
      removeDataMapViewLastLevel();
      return;
    }

    if (isLastLevelId) {
      refetchDataMapViewLastLevel();
    } else {
      refetchDataMapView();
    }
    const collection = document.getElementsByClassName('tooltip-map');
    while (collection[0]) {
      collection[0]?.parentNode?.removeChild(collection[0]);
    }
  }, [
    levelId,
    cropType,
    clientId,
    isLastLevelId,
    refetchDataMapView,
    refetchDataMapViewLastLevel,
    removeDataMapView,
    removeDataMapViewLastLevel,
  ]);

  useEffect(() => {
    dispatch(clearCircumferenceAnalytics());
    dispatch(clearVigorAnalytics());
    dispatch(clearPlantStressAnalytics());
    dispatch(clearCrownAreaAnalytics());
    dispatch(clearWaterUptakeAnalytics());
    dispatch(clearChlorophyllAnalytics());
    dispatch(clearGapAnalytics());
    dispatch(clearTreeTiltFallenAnalytics());
    dispatch(clearPlantHealthOilPailmAnalytics());
    dispatch(clearWeedInvasionAnalytics());
    dispatch(clearData());
    dispatch(changeDataPointClicked(undefined));
  }, [levelId, cropType, clientId, dispatch]);

  // clear all data after out of map
  useEffect(() => {
    return () => {
      if (mapStateKey === MapStateKey.MAP_VIEW) {
        dispatch(clearState());
        dispatch(clearTreeHeightAnalytics());
        dispatch(clearCo2SequestrationAnalytics());
      }
    };
  }, [dispatch, mapStateKey]);

  const calculatorViewport = useCallback((pointsPolygon: any[], mapRefCurrent: any) => {
    try {
      if (mapRefCurrent.current && !isEmpty(pointsPolygon)) {
        return getBoundsForPoints(pointsPolygon, mapRefCurrent.current.clientWidth, mapRefCurrent.current.clientHeight);
      }
    } catch (error) {
      console.log(error);
    }

    return DEFAULT_VIEWPORT;
  }, []);

  useEffect(() => {
    if (isMap3D) {
      const collection = document.getElementsByClassName('tooltip-map');
      while (collection[0]) {
        collection[0]?.parentNode?.removeChild(collection[0]);
      }
    }
  }, [isMap3D]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.getMap().resize();
    }
  }, [navbarMapWidth, analyticPanelWidth]);

  const markerPointLayer = useMemo(() => {
    if (!dataMapView?.data || isLastLevelId) {
      return <></>;
    }

    const allPoints: any[] = [];
    const listPointCenter: { longitude: number; latitude: number; id: string }[] = [];
    const view = dataMapView.data.map((item: any) => {
      const coordinates = get(item, 'location.features[0].geometry.coordinates[0]');
      // geojson type multipolygon and polygon
      if (coordinates) {
        if (isArray(get(coordinates, '[0][0]'))) {
          allPoints.push(...coordinates[0]);
          const pointCenterPolygon = polylabel(coordinates, 0.001);
          listPointCenter.push({
            longitude: pointCenterPolygon[0],
            latitude: pointCenterPolygon[1],
            id: item._id,
          });
          return (
            <MarkerPoint
              item={item}
              key={item._id}
              pointCenter={{
                longitude: pointCenterPolygon[0],
                latitude: pointCenterPolygon[1],
              }}
            />
          );
        } else {
          allPoints.push(...coordinates);
          const pointCenterPolygon = polylabel(item.location.features[0].geometry.coordinates, 0.001);
          listPointCenter.push({
            longitude: pointCenterPolygon[0],
            latitude: pointCenterPolygon[1],
            id: item._id,
          });
          return (
            <MarkerPoint
              item={item}
              key={item._id}
              pointCenter={{
                longitude: pointCenterPolygon[0],
                latitude: pointCenterPolygon[1],
              }}
            />
          );
        }
      }
      return <></>;
    });
    // center point icons
    dispatch(changePointCenters(listPointCenter));

    const pointCenter = calculatorViewport(allPoints, containerMapRef);
    mapRef.current?.flyTo({
      center: [pointCenter.longitude, pointCenter.latitude],
      zoom: pointCenter.zoom,
      essential: true,
      speed: 1.0,
      curve: 1,
    });

    return view;
  }, [dataMapView, calculatorViewport, isLastLevelId, containerMapRef, dispatch, mapRef]);

  const hasOrthoimageLayer = useMemo(
    () => layerTypeCurrentList.some((layer) => layer.layerType === LayerTypeEnum.RGB_ORTHOIMAGE),
    [layerTypeCurrentList]
  );

  const polygonLayerMemo = useMemo(() => {
    if (!dataMapView?.data) {
      return <></>;
    }

    return dataMapView.data.map((item: any) => (
      <PolygonLayer key={item._id} item={item} isOnTopOfOrthoimage={hasOrthoimageLayer} mapRef={mapRef} />
    ));
  }, [dataMapView, mapRef, hasOrthoimageLayer]);

  const pointLevelLast = useMemo(() => {
    if (!dataMapViewLastLevel?.data || !isLastLevelId) {
      return <></>;
    }
    const item = dataMapViewLastLevel.data;
    const coordinates = get(item, 'location.features[0].geometry.coordinates[0]');
    // geojson type multipolygon and polygon
    const multiPoint = isArray(get(coordinates, '[0][0]')) ? coordinates[0] : coordinates;
    const pointCenter = calculatorViewport(multiPoint, containerMapRef);
    if (pointCenter) {
      mapRef.current?.flyTo({
        center: [pointCenter.longitude, pointCenter.latitude],
        zoom: pointCenter.zoom,
        essential: true,
        speed: 1.0,
        curve: 1,
      });
    }

    return <PolygonLayer item={item} isOnTopOfOrthoimage={hasOrthoimageLayer} isLastLevelId mapRef={mapRef} />;
  }, [dataMapViewLastLevel, mapRef, calculatorViewport, isLastLevelId, containerMapRef, hasOrthoimageLayer]);

  const layerSky = useMemo(() => {
    return (
      <Source id="layer-map-3d" type="raster-dem" url="mapbox://mapbox.mapbox-terrain-dem-v1" tileSize={512}>
        <Layer
          id="sky"
          type="sky"
          paint={{
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': getSunPosition(viewport.latitude, viewport.longitude),
            'sky-atmosphere-sun-intensity': 15,
          }}
        />
      </Source>
    );
  }, [viewport]);

  const imageAnalytic = useMemo(() => {
    const layerList = layerTypeCurrentList.filter((item) => item.layerType);

    return (
      <>
        {layerList?.map((_item: any, index: number) => {
          return (
            <Source
              key={`raster-${_item.analysisId._id}-${_item.analysisId.name}-${_item.layerType}`}
              id={`raster-${_item.analysisId._id}-${_item.analysisId.name}-${_item.layerType}`}
              type="raster"
              tiles={[
                `${configs.API_DOMAIN}/data-analytics/getPathDataAnalysisDefault/${_item.analysisId._id}/{z}/{x}/{y}.png?layerType=${_item.layerType}`,
              ]}>
              <Layer
                id={`layer-${index}`}
                beforeId="sky"
                type="raster"
                layout={{
                  visibility: layerTypeList.some((_i: any) => _i.layerType === _item.layerType) ? 'visible' : 'none',
                }}
              />
            </Source>
          );
        })}
        {polygonAnalytics}
      </>
    );
  }, [layerTypeList, layerTypeCurrentList, polygonAnalytics]);

  const handleEditDetail = useCallback(
    (properties: any) => {
      switch (analyticName) {
        case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
          dispatch(
            changeCircumferenceEditedDetail({
              ...properties,
              initialDiaLength: properties.diaLength,
            })
          );
          break;

        case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
          dispatch(changeDrawCrownAreaState({ crownAreaEditedDetail: properties }));
          break;
        default:
      }
    },
    [analyticName, dispatch]
  );

  const onMouseMove = useCallback((event: any) => {
    const listFeatures = mapRef.current?.queryRenderedFeatures(event.point);
    const vectorFeature = listFeatures.filter((item: any) => item.layer?.id === 'vector_contour');
    vectorFeature.length
      ? setHoverVectorInfo({ elev: vectorFeature[0].properties.elev, lat: event.lngLat.lat, lng: event.lngLat.lng })
      : setHoverVectorInfo(undefined);

    const { features } = event;
    if (!features || !features[0]) {
      setHoverInfo(null);
      return;
    }
    const hoveredFeature = features && features[0];
    hoveredFeature.properties.latY = hoveredFeature.properties.latY || event.lngLat.lat;
    hoveredFeature.properties.longX = hoveredFeature.properties.longX || event.lngLat.lng;
    setHoverInfo(hoveredFeature);
  }, []);

  const onMouseClick = useCallback(
    (event: any) => {
      isShowPopupAdd && dispatch(changePointStandCount({ latitude: event.lngLat.lat, longitude: event.lngLat.lng }));

      const { features } = event;
      if (!features || !features[0]) {
        changeDataPointClicked(undefined);
        return;
      }
      const clickedFeature = features && features[0];
      isPointerMode && dispatch(changeDataPointClicked(clickedFeature.properties));
      if (isInEditTab && isStandPointEditMode) {
        handleEditDetail(clickedFeature.properties);
        dispatch(changeClickedStandPointId(clickedFeature.properties._id));
      }
    },
    [isShowPopupAdd, dispatch, isPointerMode, isInEditTab, isStandPointEditMode, handleEditDetail]
  );

  const onMapMove = (event: ViewStateChangeEvent) => {
    if (mapStateKey === MapStateKey.MAP_VIEW && !isLockMap && !isLockMapEditCrownArea) setViewport(event.viewState);
  };

  const onMapMoveEnd = (event: ViewStateChangeEvent) => {
    if (mapStateKey === MapStateKey.MAP_POPUP && comparedIndex) {
      dispatch(changeViewPort({ data: event.viewState, comparedIndex }));
      dispatch(changeAbleTransport(true));
    }
  };

  const setMapViewState = (viewState: ViewState) => {
    const { longitude, latitude, zoom } = viewState;
    dispatch(changeMapViewState({ longitude, latitude, zoom }));
  };

  const handleChangeStyleMap = (style: string) => {
    if (mapStateKey === MapStateKey.MAP_VIEW) setMapStyle(style);
    else dispatch(changeMapStyle(style));
  };
  const conditionalViewPortRendering = useMemo(
    () => (comparedIndex ? { ...mapPopupViewPort } : { ...viewport }),
    [mapPopupViewPort, viewport, comparedIndex]
  );

  return (
    <>
      <ToolMapView showAnalytics={showAnalytics} openAnalytic={openAnalytic} />
      <Grid
        width="100%"
        height="100%"
        ref={containerMapRef}
        onMouseMove={(e) => {
          mouseRef.current.clientX = e.clientX;
          mouseRef.current.clientY = e.clientY;
        }}
        sx={{
          '& .mapboxgl-ctrl-top-left': {
            left: '12px',
            '& .active': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        }}>
        <ReactMapGL
          {...conditionalViewPortRendering}
          ref={mapRef}
          mapboxAccessToken={configs.MAP_BOX_API}
          {...settingsMap}
          mapStyle={mapStyle}
          initialViewState={DEFAULT_VIEWPORT}
          onMove={onMapMove}
          onMoveEnd={onMapMoveEnd}
          cursor={isShowPopupAdd ? 'pointer' : 'auto'}
          interactiveLayerIds={interactiveLayerIds}
          onMouseLeave={() => {
            setHoverInfo(null);
          }}
          onMouseMove={onMouseMove}
          onZoomEnd={(e) => setMapViewState(e.viewState)}
          onDragEnd={(e) => setMapViewState(e.viewState)}
          onClick={onMouseClick}
          terrain={isMap3D ? { source: 'layer-map-3d', exaggeration: 0.5 } : undefined}>
          {layerSky}
          {pointLevelLast}
          {polygonLayerMemo}
          {markerPointLayer}
          {arcLayer}
          {mapStateKey === MapStateKey.MAP_VIEW && imageAnalytic}
          <MapViewMarker hoverInfo={hoverInfo} mouseRef={mouseRef} />

          <DropMarker />
          <DrawIssueShape />
          <ShowIssueShape />

          <DrawCrownAreaShape />
          <VectorContourLayer hoverVectorInfo={hoverVectorInfo!} mode={MapEnum.MAP_VIEW} />
          {mapStateKey === MapStateKey.MAP_VIEW && (
            <>
              <StreamlineOrderLayer mode={MapEnum.MAP_VIEW} />
            </>
          )}
          <LinesVectorLayer />
          <NavigationControl position="bottom-right" showCompass={false} />
          {mapStateKey === MapStateKey.MAP_POPUP && listDateInfo.length === 2 && comparedIndex && (
            <ShowOrthoImageInMapPopup comparedIndex={comparedIndex} listDateInfo={listDateInfo} />
          )}

          {isPolygonMode ? <DrawPolygonStandCount /> : null}
          <StandCountBoundary />
        </ReactMapGL>
        <Minimap onChangeStyleMap={(style) => handleChangeStyleMap(style)} />
      </Grid>
    </>
  );
};

import { Add } from '@mui/icons-material';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TabPanel } from './ClientLevelProperties';
import { ClientMenu } from './ClientMenu';
import { ICropTypeTab, ILevelColumn, IPropsInLevel } from './ClientsInfo.type';

export const CropTypeTab = ({
  tabIndex,
  control,
  index,
  type,
  levelNumberArray,
  indexLevel,
  setIndexLevel,
  anchorEl,
  openMenu,
  closeMenu,
  setOpenDeleteModal,
  handleOpenModal,
  fields,
  setShowMenuIconLevel,
  showMenuIconLevel,
  setEditData,
  handleAddLevelButton,
  levelCount,
}: ICropTypeTab) => {
  const theme = useTheme();
  return (
    <TabPanel value={tabIndex} index={index} key={type.value + tabIndex}>
      <Box
        sx={{
          mt: '80px',
          width: '100%',
          display: 'flex',
        }}>
        {levelNumberArray && levelNumberArray.length > 0 && (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mr: '16px',
            }}>
            {levelNumberArray.map((level: any, index: number) => {
              return (
                <LevelColumn
                  control={control}
                  level={level}
                  type={type}
                  setIndexLevel={setIndexLevel}
                  indexLevel={indexLevel}
                  anchorEl={anchorEl}
                  openMenu={openMenu}
                  closeMenu={closeMenu}
                  setOpenDeleteModal={setOpenDeleteModal}
                  handleOpenModal={handleOpenModal}
                  fields={fields}
                  setShowMenuIconLevel={setShowMenuIconLevel}
                  showMenuIconLevel={showMenuIconLevel}
                  setEditData={setEditData}
                  key={index}
                />
              );
            })}
          </Stack>
        )}

        <Button
          endIcon={<Add />}
          color="neutral"
          variant="outlined"
          sx={{
            lineHeight: '17px',
            border: `1px solid ${theme.palette.primary.main}`,
            height: '56px',
            width: '220px',
            minWidth: '220px',
            maxWidth: '220px',
            justifyContent: 'space-between',
            textTransform: 'none',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              opacity: '0.8',
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
              border: `1px solid ${theme.palette.primary.main}`,
            },
          }}
          onClick={() => handleAddLevelButton(index)}
          disabled={levelCount >= 5 || levelNumberArray.length === 5}>
          Add Level
        </Button>
      </Box>
    </TabPanel>
  );
};

const LevelColumn = ({
  control,
  level,
  type,
  setIndexLevel,
  indexLevel,
  anchorEl,
  openMenu,
  closeMenu,
  setOpenDeleteModal,
  handleOpenModal,
  fields,
  setShowMenuIconLevel,
  showMenuIconLevel,
  setEditData,
}: ILevelColumn) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '220px',
        maxWidth: '220px',
      }}
      key={`level-${level}`}>
      <Box
        sx={{
          px: '12px',
          height: '56px',
          border: (theme) =>
            theme.palette.mode === 'dark' ? `1px solid ${theme.palette.divider}` : '1px solid transparent',
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '8px 8px 0 0',
        }}
        onMouseLeave={() => setIndexLevel(null)}
        onMouseEnter={() => setIndexLevel(level)}>
        <Typography
          sx={{
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            fontWeight: '500',
            color: (theme) => (theme.palette.mode === 'dark' ? '#C7C7C7' : '#5F5F5F'),
          }}>
          {t('trans.level')} {level}
        </Typography>
        {indexLevel !== 1 && indexLevel === level && (
          <ClientMenu
            anchorEl={anchorEl.removeLevel}
            open={Boolean(anchorEl.removeLevel)}
            onOpen={openMenu('removeLevel', '', level, true)}
            onClose={closeMenu('removeLevel')}
            onDelete={() => setOpenDeleteModal(true)}
            mainMenu={false}
          />
        )}
      </Box>

      <Button
        endIcon={<Add />}
        sx={{
          padding: '12px',
          width: '220px',
          maxWidth: '220px',
          height: '56px',
          backgroundColor: '#009F401A',
          textTransform: 'none',
          fontFamily: 'Barlow',
          fontSize: '14px',
          lineHeight: '17px',
          color: theme.palette.primary.main,
          fontWeight: '500',
          justifyContent: 'space-between',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '0',
          '.MuiButton-endIcon': {
            m: 0,
          },
          '&:hover': {
            backgroundColor: '#009F401A',
            color: theme.palette.primary.main,
            opacity: '0.8',
          },
        }}
        onClick={() => handleOpenModal(level)}>
        Add New Property
      </Button>
      <Box
        sx={{
          pb: '10px',
        }}>
        {fields
          .filter((res: any) => res.level === level && res.cropType === type.value)
          .map((property: any, index: number) => {
            return (
              <PropsInLevel
                key={index}
                control={control}
                level={level}
                fields={fields}
                setShowMenuIconLevel={setShowMenuIconLevel}
                property={property}
                showMenuIconLevel={showMenuIconLevel}
                anchorEl={anchorEl}
                openMenu={openMenu}
                closeMenu={closeMenu}
                setEditData={setEditData}
                setOpenDeleteModal={setOpenDeleteModal}
                handleOpenModal={handleOpenModal}
              />
            );
          })}
      </Box>
    </Box>
  );
};

const PropsInLevel = ({
  control,
  level,
  setShowMenuIconLevel,
  property,
  showMenuIconLevel,
  anchorEl,
  openMenu,
  closeMenu,
  setEditData,
  handleOpenModal,
  setOpenDeleteModal,
  fields,
}: IPropsInLevel) => {
  const index = fields.findIndex((res: any) => res.id === property.id);
  return (
    <Controller
      name={`optionalProperties.${index}.name`}
      // eslint-disable-next-line react/no-array-index-key
      key={`optionalProperties.${index}.name`}
      control={control}
      render={({ field }) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '220px',
            height: '56px',
            maxWidth: '220px',
            borderRadius: 'none',
            borderWidth: '0px 1px 1px 1px',
            borderStyle: 'solid',
            borderColor: (theme) => (theme.palette.mode === 'dark' ? '#C7C7C7' : '#F2F2F2'),
            overflow: 'hidden',
            '&:last-child': {
              borderRadius: '0 0 8px 8px',
            },
          }}
          onMouseLeave={() => setShowMenuIconLevel(null)}
          onMouseEnter={() => setShowMenuIconLevel(index)}>
          <Typography
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              pl: '10px',
              textTransform: 'none',
              fontFamily: 'Barlow',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '17px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#C7C7C7' : '#616161'),
              backgroundColor: (theme) => (theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'),
              '&:hover': {
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.background.paper : '#F2F2F2',
              },
            }}
            {...field}>
            {property.name}
          </Typography>
          {showMenuIconLevel === index && (
            <ClientMenu
              anchorEl={anchorEl.level}
              open={Boolean(anchorEl.level)}
              onOpen={openMenu('level', property.name, level)}
              onClose={closeMenu('level')}
              handleEdit={() => {
                setEditData({
                  id: property.id,
                  name: property.name,
                  type: property.type,
                  value: property.value,
                });
                handleOpenModal(level);
              }}
              onDelete={() => setOpenDeleteModal(true)}
              mainMenu={false}
            />
          )}
        </Box>
      )}
    />
  );
};

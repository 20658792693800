/* eslint-disable no-underscore-dangle */

import { Box, Typography, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientListByPagination } from 'services/clients/apiClient.services';

interface IClientsListing {
  tab: string;
}

export const ClientsListing = ({ tab }: IClientsListing) => {
  const { clientId } = useParams();
  const { data: clientData } = useQuery(QUERY_KEY.CLIENT_LIST, () => getClientListByPagination(1, 10, ''), {
    keepPreviousData: false,
  });
  const clientsDataMap = useMemo(() => clientData?.data?.data, [clientData]);
  const theme = useTheme();
  const { t } = useTranslation();

  const styles = {
    titleDashboard: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#3C4043',
    },
  };

  const nameClient = useMemo(() => {
    const clientSelected = clientsDataMap?.find((info: any) => info._id === clientId);
    return clientSelected ? clientSelected.name : t('trans.recent_clients');
  }, [clientId, clientsDataMap]);

  return (
    <Box
      sx={{
        px: '28px',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'end',
          my: '20px',
        }}>
        <Typography
          sx={{
            ...styles.titleDashboard,
          }}>
          {nameClient}
        </Typography>
      </Box>
    </Box>
  );
};

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, Popover, Typography, useTheme } from '@mui/material';
import { DEFAULT_BORDER_COLOR } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { queryMe } from 'services/clients/apiClient.services';
import { saveColorsAnalyticLayer } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeIsDisplayBoundary, changeLayer3D, mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import SideSurface from '../SideSurface';
import { SwitchRaster } from '../SwitchRaster';
import TopSurface from '../TopSurface';

const SoilMoistureContentCardLayer = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isShowMultiDOption, setIsShowMultiDOption] = useState<boolean>(true);
  const [isShowRaster, setIsShowRaster] = useState<boolean>(true);
  const [selectedColor, setSelectedColor] = useState<string>(DEFAULT_BORDER_COLOR);

  const { clientSetting, colorAnalysisLayer } = useQueryClientSetting();
  const classes = useLayerSelectCardStyle();
  const { analyticId, isLayer3D, isDisplayBoundaryLine } = useAppSelector(mapViewSelector);
  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const colorFromClientSetting = colorAnalysisLayer?.find(
    (item) => item.analysisId === analyticId && item.userId === userInfo?.data?._id
  )?.color;
  const { analyticName } = useAppSelector(rightBarSelector);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { t } = useTranslation();

  // set initial color from client setting
  useEffect(() => {
    colorFromClientSetting && setSelectedColor(colorFromClientSetting);
  }, [colorFromClientSetting]);

  // set 2dContour option is default
  useEffect(() => {
    dispatch(changeIsDisplayBoundary(true));
  }, [dispatch]);

  const handleShowColorPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const updateColorMutation = useMutation(saveColorsAnalyticLayer, {
    onSuccess() {
      toast.success('Update color successful');
      queryClient.invalidateQueries([QUERY_KEY.CLIENT_SETTINGS_BY_ID]);
    },
  });
  const handleChangeColor = () => {
    updateColorMutation.mutate({ analysisId: analyticId!, settingId: clientSetting?._id!, color: selectedColor });
  };

  const { isRTL } = useCheckRTL();

  return (
    <>
      {analyticId && analyticName === TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT && (
        <>
          <Grid item xs={12} md={12}>
            <Button
              startIcon={isShowRaster ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              onClick={() => setIsShowRaster(!isShowRaster)}
              classes={{ root: classes.buttonTextStyle }}>
              {t('trans.raster')}
            </Button>
            <Collapse in={isShowRaster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              <SwitchRaster analyticType={analyticName} isShowInlineBlock={true} />
            </Collapse>
          </Grid>
          <Button
            startIcon={isShowMultiDOption ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setIsShowMultiDOption(!isShowMultiDOption)}
            classes={{ root: classes.buttonTextStyle }}>
            {t('analytic.soil_moisture_content')}
          </Button>
          <Collapse in={isShowMultiDOption} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <FormControlLabel
                  labelPlacement={isRTL ? 'start' : 'end'}
                  control={
                    <SwitchCustom
                      checked={isDisplayBoundaryLine}
                      onClick={() => dispatch(changeIsDisplayBoundary(!isDisplayBoundaryLine))}
                    />
                  }
                  label={<Typography className={classes.selectText}>{t('trans.2d_contour')}</Typography>}
                />
              </Grid>
              {!isDisplayBoundaryLine && (
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    labelPlacement={isRTL ? 'start' : 'end'}
                    control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                    label={
                      <Typography className={classes.selectText}>
                        {isLayer3D ? t('trans.3d_fill') : t('trans.2d_fill')}
                      </Typography>
                    }
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.color.grey3}`,
                  margin: '10px 0',
                }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  component="button"
                  onClick={handleShowColorPopover}
                  sx={{
                    display: 'inline-block',
                    width: '14px',
                    height: '14px',
                    borderRadius: '50%',
                    backgroundColor: selectedColor,
                    cursor: 'pointer',
                    borderWidth: 0,
                  }}
                />
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? '#ffffff' : '#3C4043',
                    display: 'inline-block',
                    ml: '4px',
                    fontSize: '12px',
                  }}>
                  Colour
                </Typography>
              </Box>
            </Grid>
          </Collapse>
        </>
      )}
      {isLayer3D && <SideSurface />}
      <TopSurface />
      <Popover
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{
          '.MuiPopover-paper': {
            overflowX: 'initial',
            overflowY: 'initial',
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 160,
          horizontal: 30,
        }}>
        <Box>
          <HexColorPickerDebounce color={selectedColor} onChange={(_color: string) => setSelectedColor(_color)} />
          <Box sx={{ my: '8px', textAlign: 'center' }}>
            <Button color="inherit" variant="outlined" size="small" onClick={() => setAnchorEl(null)}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" size="small" sx={{ ml: '12px' }} onClick={handleChangeColor}>
              Save
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default SoilMoistureContentCardLayer;

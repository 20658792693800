import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const styles = {
  userListTitle: {
    py: '10px',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18.2px',
    textAlign: 'center',
  },
  clientUpdateTime: {
    textAlign: 'center',
    px: '16px',
  },
};

const InfoPolygonLayer = () => {
  const queryClient = useQueryClient();
  const { clientId } = useParams();
  const { levelId, cropType } = useAppSelector(mapViewSelector);
  const [nameSort, setNameSort] = useState(true);
  const [typeSort, setTypeSort] = useState(true);
  const [unitSort, setUnitSort] = useState('area');

  const { t } = useTranslation();

  const dataLevel = queryClient.getQueryData([QUERY_KEY.DATA_MAP_KEY, clientId, cropType, levelId]) as any;
  const dataLevelLast = queryClient.getQueryData([
    `${QUERY_KEY.DATA_MAP_KEY}_CLIENT`,
    clientId,
    cropType,
    levelId,
  ]) as any;

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const isShowSlopeArea = useMemo(() => {
    if (!clientSetting || !clientSetting?.viewAnalysisPropperties) return false;
    return clientSetting?.viewAnalysisPropperties?.find((data: any) => data.name === 'SHOW/HIDE SLOPE ANALYSIS')?.value;
  }, [clientSetting]);

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber(area * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(area * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(area * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return new BigNumber(area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const tableAreaPolygon = useMemo(() => {
    if (dataLevel?.data) {
      const sortedArray = [...dataLevel?.data]?.sort((a: any, b: any) => {
        if (unitSort === 'area') {
          return typeSort ? a.area - b.area : b.area - a.area;
        }
        if (unitSort === 'name') {
          return nameSort
            ? a.name.toLowerCase() > b.name.toLowerCase()
              ? 0
              : -1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? 0
            : -1;
        } else {
          return typeSort ? a.totalSlopeArea - b.totalSlopeArea : b.totalSlopeArea - a.totalSlopeArea;
        }
      });

      return sortedArray.map((_item: any, i: number) => {
        let tmp = _item?.landUseAnalysisSummary?.reduce((pre: any, curentValue: any) => {
          return pre + curentValue.slopeArea;
        }, 0);
        return (
          <TableRow key={i}>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                textAlign: 'left',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
              }}>
              {`${_item.name}`}
            </TableCell>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
              }}>
              {clientAreaUnitMeasurement(_item.area || 0)}
            </TableCell>
            {isShowSlopeArea && (
              <TableCell
                component="td"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                }}>
                {clientAreaUnitMeasurement(tmp || 0)}
              </TableCell>
            )}
          </TableRow>
        );
      });
    } else if (dataLevelLast?.data) {
      let tmpSlopeArea = dataLevelLast?.data?.landUseAnalysisSummary.reduce(
        (preValue: any, currentValue: any) => preValue + currentValue?.slopeArea,
        0
      );
      return (
        <TableRow>
          <TableCell
            component="td"
            sx={{
              ...styles.clientUpdateTime,
              textAlign: 'left',
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
            }}>
            {`${dataLevelLast?.data.properties.find((_: any) => _.name === 'Name')?.value?.[0] || ''}`}
          </TableCell>
          <TableCell
            component="td"
            sx={{
              ...styles.clientUpdateTime,
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
            }}>
            {clientAreaUnitMeasurement(dataLevelLast?.data.area || 0)}
          </TableCell>
          {isShowSlopeArea && (
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
              }}>
              {clientAreaUnitMeasurement(tmpSlopeArea || 0)}
            </TableCell>
          )}
        </TableRow>
      );
    }
    return <></>;
  }, [dataLevel?.data, dataLevelLast?.data, unitSort, typeSort, nameSort, clientAreaUnitMeasurement, isShowSlopeArea]);

  const getTotalArea = useMemo(() => {
    if (dataLevel?.data) {
      const sum = dataLevel.data.reduce((accumulator: any, item: any) => accumulator + item.area, 0);
      return clientAreaUnitMeasurement(sum || 0);
    } else if (dataLevelLast?.data) {
      return clientAreaUnitMeasurement(dataLevelLast.data.area || 0);
    }
  }, [dataLevel, dataLevelLast, clientAreaUnitMeasurement]);

  const getTotalSlopeArea = useMemo(() => {
    if (dataLevel?.data) {
      const sum = dataLevel.data.reduce((accumulator: any, item: any) => accumulator + item.totalSlopeArea, 0);
      return clientAreaUnitMeasurement(sum || 0);
    } else if (dataLevelLast?.data) {
      return clientAreaUnitMeasurement(dataLevelLast.data?.totalSlopeArea || 0);
    }
  }, [dataLevel, dataLevelLast, clientAreaUnitMeasurement]);

  const handleSortTable = useCallback(
    (unit: string) => {
      setTypeSort(!typeSort);
      setUnitSort(unit);
      setNameSort(false);
    },
    [typeSort]
  );
  const handleSortByName = useCallback(
    (unit: string) => {
      setNameSort(!nameSort);
      setUnitSort(unit);
      setTypeSort(false);
    },
    [nameSort]
  );
  const theme = useTheme();
  const { isRTL } = useCheckRTL();

  return (
    <TableContainer
      sx={{
        overflow: 'hidden',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        mt: '-1px',
        mb: '12px',
        direction: isRTL ? 'rtl' : 'ltr',
      }}>
      <Table>
        <TableHead>
          <TableRow
            component="tr"
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
            }}>
            <TableCell
              component="th"
              sx={{
                ...styles.userListTitle,
                minWidth: '150px',
                textAlign: 'left',
                color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {t('trans.level')}
                <SvgIcon
                  component={nameSort ? DescIcon : AscIcon}
                  inheritViewBox
                  sx={{ fontSize: '14px', cursor: 'pointer' }}
                  onClick={() => handleSortByName('name')}
                />
              </Box>
            </TableCell>
            <TableCell
              component="th"
              sx={{
                ...styles.userListTitle,
                color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {`${t('trans.area')} (${areaUnit})`}
                <SvgIcon
                  component={typeSort ? DescIcon : AscIcon}
                  inheritViewBox
                  sx={{ fontSize: '14px', cursor: 'pointer' }}
                  onClick={() => handleSortTable('area')}
                />
              </Box>
            </TableCell>
            {isShowSlopeArea && (
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {`Slope area (${areaUnit})`}
                  <SvgIcon
                    component={typeSort ? DescIcon : AscIcon}
                    inheritViewBox
                    sx={{ fontSize: '14px', cursor: 'pointer' }}
                    onClick={() => handleSortTable('slopeArea')}
                  />
                </Box>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            pt: 0,
            flexDirection: 'column',
            minHeight: '510px',
            maxHeight: '510px',
            overflowY: 'scroll',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
          {tableAreaPolygon}
          {dataLevel?.data.length > 1 && (getTotalArea || getTotalSlopeArea) ? (
            <TableRow>
              <TableCell
                component="td"
                sx={{
                  ...styles.clientUpdateTime,
                  textAlign: 'left',
                  fontWeight: 'bold',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                }}>
                {t('trans.total')}
              </TableCell>
              <TableCell
                component="td"
                sx={{
                  ...styles.clientUpdateTime,
                  fontWeight: 'bold',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                }}>
                {getTotalArea}
              </TableCell>
              {isShowSlopeArea && (
                <TableCell
                  component="td"
                  sx={{
                    ...styles.clientUpdateTime,
                    fontWeight: 'bold',
                    color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  }}>
                  {getTotalSlopeArea}
                </TableCell>
              )}
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InfoPolygonLayer;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationAr from './ar.json';
import translationEn from './en.json';
import translationJp from './jp.json';
import translationPt from './pt.json';

const resources = {
  en: {
    translation: translationEn,
  },
  jp: {
    translation: translationJp,
  },
  pt: {
    translation: translationPt,
  },
  ar: {
    translation: translationAr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'jp',
});

export default i18n;

import { Box, Button, Grid, InputLabel, Skeleton, Typography, useTheme } from '@mui/material';
import { ButtonCropType } from 'components/Common/ButtonCropType';
import { QUERY_KEY } from 'constants/constants';
import { useCreateTreeLevel } from 'hooks/useClientProperties';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getListFavouritesLocation } from 'services/analytics/apiAnalyticsConfig.services';
import { getClientById } from 'services/clients/apiClient.services';
import { getAllSettingsDataCroptype } from 'services/settings/settings.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeCropType, changeLevelId, mapViewSelector } from 'store/slices/mapViewSlice';
import { clearVigorAnalytics } from '../../../store/slices/map-view/vigorAnalytics';
import { LevelTree } from './LevelTree';

export const FavoritesLocation = () => {
  const { clientId } = useParams();
  const { cropType } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();
  const [isShowMoreBtn, setIsShowMoreBtn] = useState(false);
  const scrollBlockRef = useRef() as any;
  const bottomBock = useRef() as React.MutableRefObject<HTMLDivElement>;
  const theme = useTheme();
  const { t } = useTranslation();

  const mutationCreateTreeLevel = useCreateTreeLevel();
  const { data: cropTypeData } = useQuery([QUERY_KEY.USER_SETTINGS_CROPTYPE], () => getAllSettingsDataCroptype());
  const dataSettingsAnalyticsValue = useMemo(() => {
    return cropTypeData?.data || [];
  }, [cropTypeData]);

  const setLevelId = useCallback(() => {
    dispatch(changeLevelId({ levelId: undefined, isLevelLasted: undefined }));
    //reset vigor analytic
    dispatch(clearVigorAnalytics());
  }, [dispatch]);

  const setCropTypeSelected = useCallback(
    (type?: string) => {
      dispatch(changeCropType(type));
    },
    [dispatch]
  );

  const { data: clientData } = useQuery([QUERY_KEY.CLIENT_DATA, clientId], () => getClientById(clientId || ''), {
    enabled: !!clientId,
  });

  const {
    data: levelList,
    isFetching,
    refetch: refetchLevelTree,
    remove: removeCacheLevelList,
  } = useQuery(
    [QUERY_KEY.FAVORITE_LOCATION_LIST, clientId, cropType],
    () => getListFavouritesLocation(clientId || '', cropType || ''),
    {
      enabled: !!clientId && !!cropType,
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    removeCacheLevelList();
    setCropTypeSelected();
    setLevelId();
  }, [clientId, setCropTypeSelected, setLevelId, removeCacheLevelList]);

  const onCreateLevel = (parent: string | null, clientIdParam: string, cropTypeParam: any, name: string) =>
    mutationCreateTreeLevel.mutate(
      { parent, clientId: clientIdParam, cropType: cropTypeParam, name },
      {
        onSuccess: (res: any) => {
          if (res?.data?.status === 400) {
            toast.error(res.data.response.message, { toastId: 1 });
            return;
          }
          refetchLevelTree();
        },
      }
    );
  const cropTypeOptions = useMemo(() => {
    return clientData?.cropType || [];
  }, [clientData]);
  const maxLevel = useMemo(() => {
    if (!clientData || !cropType) {
      return 0;
    }

    return clientData.numberOfLevel?.find((item: any) => item.type === cropType)?.value || 0;
  }, [clientData, cropType]);

  const addNewLevelHierarchyStructure = (parent: string | null) => {
    if (cropType && clientId) {
      onCreateLevel(parent, clientId, cropType, 'Untitled');
    }
  };

  const skeletonProperties = useMemo(() => {
    return (
      <Typography component="ul" sx={{ pl: 0, pt: 2, padding: 2 }}>
        {Array.from(Array(4).keys()).map((item) => (
          <Box key={item}>
            <Skeleton variant="text" width="100%" height="30px" />
          </Box>
        ))}
      </Typography>
    );
  }, []);

  const test = useMemo(() => {
    return dataSettingsAnalyticsValue.filter((item: any) => cropTypeOptions.some((val: any) => val._id === item._id));
  }, [dataSettingsAnalyticsValue, cropTypeOptions]);

  return (
    <Grid direction="row">
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 2, sm: 2, md: 2 }}
        sx={{
          mb: '1rem',
        }}>
        {test
          .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
          .map((item: any) => (
            <Grid item display="flex" gap="10px">
              <ButtonCropType
                isSelected={item._id === (cropType || '')}
                label={item.name}
                icon={item.icon}
                selectedCropType={() => {
                  if (cropType !== item._id) {
                    setCropTypeSelected(item._id);
                  } else {
                    setCropTypeSelected();
                  }
                  setLevelId();
                }}
              />
            </Grid>
          ))}
      </Grid>
      <Grid>
        <Box
          sx={{
            display: 'flex',
          }}>
          <InputLabel
            sx={{
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3c4043'),
              fontWeight: 500,
              fonSize: '16px',
              textTransform: 'unset',
            }}>
            {t('mapView.list_of_favorites_locations')}
          </InputLabel>
        </Box>
        {isFetching && skeletonProperties}
        {!isFetching && (
          <Box>
            <Scrollbars
              style={{ height: 'calc(100vh - 420px)' }}
              onScrollFrame={(values: any) => {
                if (values.top === 1 || values.top === 0) {
                  setIsShowMoreBtn(false);
                } else {
                  setIsShowMoreBtn(true);
                }
              }}>
              <LevelTree
                levels={levelList?.data || []}
                maxLevel={maxLevel}
                onNodeAdded={addNewLevelHierarchyStructure}
                isFavorite={true}
              />
              <div ref={bottomBock}></div>
              <Box
                sx={{
                  display: isShowMoreBtn ? 'block' : 'none',
                  width: '100px',
                  height: '40px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  backgroundColor: 'transparent',
                  position: 'fixed',
                  bottom: '28px',
                  opacity: isShowMoreBtn ? '1' : '0',
                  transition: 'all 0.3s ease',
                  left: 0,
                  right: 0,
                }}>
                <Button
                  sx={{
                    maxWidth: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                    padding: '5px',
                    border: `1px solid ${theme.palette.primary.main}`,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5)',
                    margin: 'auto',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                      color: '#fff',
                    },
                  }}
                  onClick={() => {
                    scrollBlockRef.current.view.scroll({
                      top: 10000,
                      behavior: 'smooth',
                    });
                  }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      width: '200px',
                    }}>
                    {t('trans.more')}
                  </Typography>
                </Button>
              </Box>
            </Scrollbars>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

import { Grid, InputLabel, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FILE_TYPE, mapFileLabel } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { Formik } from 'formik';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QUERY_KEY } from '../../../../constants/constants';
import { createGapAnalytic, getRasterDetails } from '../../../../services/analytics/apiAnalyticsConfig.services';
import { ButtonCustom } from '../../../Common/ButtonCustom';
import SelectFile from '../../../Common/SelectFile';
import { ErrorsDialog } from './ErrorDialog';

interface IGapForm {
  sensorId: any;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

export const GapForm: FC<IGapForm> = ({ sensorId, deleteFileGeojson }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const theme = useTheme();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery([QUERY_KEY.RASTER_DETAILS, sensorId], () =>
    getRasterDetails(sensorId)
  );

  const createAnalyticMutation = useMutation((data: any) => createGapAnalytic(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY).then(() => {});
      refetch().then(() => {});
      toast.success('Update Gap Analytic Successful', { toastId: 1 });
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message);
    },
  });

  useEffect(() => {
    formRef.current && formRef.current.resetForm();
  }, [sensorId, formRef]);

  const initialFields = useMemo(() => {
    let initParam = { file: undefined, intervalLimitFile: undefined };
    if (analyticDetails?.data?.uploadHistory) {
      analyticDetails.data.uploadHistory.forEach((_item: any) => {
        if (_item.geoJsonType) {
          initParam = {
            ...initParam,
            intervalLimitFile: _item,
          };
        } else {
          initParam = {
            ...initParam,
            file: _item,
          };
        }
      });
    }
    return initParam;
  }, [analyticDetails]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          formData.append('analysisId', sensorId);
          formData.append('dataFile', values.file || '');
          formData.append('intervalLimitFile', values.intervalLimitFile || '');
          createAnalyticMutation.mutate(formData, {
            onSuccess: () => {
              resetForm();
            },
          });
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm }) => (
          <form onSubmit={handleSubmit}>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.GAP_FILE], 'file_name'))}
                  </InputLabel>
                  <SelectFile
                    analysisId={sensorId}
                    acceptFile={new RegExp(/.json$/)}
                    fileSelected={values.file}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('file', file);
                    }}
                    fileType={FILE_TYPE.GAP_FILE}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.INTERVALS_FILE], 'file_name'))}
                  </InputLabel>
                  <SelectFile
                    analysisId={sensorId}
                    acceptFile={new RegExp(/.json$/)}
                    fileSelected={values.intervalLimitFile}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('intervalLimitFile', file);
                    }}
                    fileType={FILE_TYPE.INTERVALS_FILE}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
              </Grid>
              <Grid marginLeft={'auto'}>
                <ButtonCustom
                  title="Save"
                  border="0px"
                  colorLabel="#FFF"
                  sx={{ width: '160px', height: '1.4375em' }}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => submitForm()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};

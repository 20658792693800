import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, Slider, styled, Typography } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeIsSameSideSurface, changeTopSurfaceOpacity, mapViewSelector } from 'store/slices/mapViewSlice';
import { change3dPolygonValue, rightBarSelector } from 'store/slices/rightBarSlice';

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 24,
    height: 24,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      position: 'absolute',
      top: '3px',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

interface TopSurfaceProps {
  id?: string;
}

export const TopSurface: FC<TopSurfaceProps> = ({ id = '' }) => {
  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();
  const [isDisplayExpand, setIsDisplayExpand] = useState(false);
  const { t } = useTranslation();

  const mapViewState = useAppSelector(mapViewSelector);
  const {
    issuesTab: { polygon3dList },
  } = useAppSelector(rightBarSelector);
  const foundPolygon3d = polygon3dList.find((item) => item.id === id);

  const { isSameSideSurface, topSurfaceOpacity, isLayer3D } = !id ? mapViewState : foundPolygon3d || {};

  const handleChangeOpacityTopSurface = useCallback(
    (e: any) => {
      id
        ? dispatch(change3dPolygonValue({ id, topSurfaceOpacity: e.target.value }))
        : dispatch(changeTopSurfaceOpacity(e.target.value));
    },
    [dispatch, id]
  );

  const handleChecked3D = useCallback(
    (_: any) => {
      id
        ? dispatch(change3dPolygonValue({ id, isSameSideSurface: !isSameSideSurface }))
        : dispatch(changeIsSameSideSurface(!isSameSideSurface));
    },
    [id, dispatch, isSameSideSurface]
  );

  const { isRTL } = useCheckRTL();

  return (
    <div>
      <Button
        startIcon={isDisplayExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        onClick={() => setIsDisplayExpand(!isDisplayExpand)}
        classes={{ root: classes.buttonTextStyle }}>
        {t('trans.top_surface')}
      </Button>
      <Collapse in={isDisplayExpand} timeout="auto" unmountOnExit sx={{ px: 1 }}>
        <Grid container>
          {isLayer3D && (
            <Grid item xs={12}>
              <Box sx={{ px: 1.5, flexDirection: isRTL ? 'row-reverse' : 'row', gap: isRTL ? '15px' : '0px' }}>
                <FormControlLabel
                  labelPlacement={isRTL ? 'start' : 'end'}
                  control={<SwitchCustom checked={isSameSideSurface} onClick={handleChecked3D} />}
                  label={
                    <Typography className={classes.selectText}>{t('trans.same_opacity_as_side_surface')}</Typography>
                  }
                />
              </Box>
            </Grid>
          )}
          {!isLayer3D && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pt: 1,
                  paddingLeft: '8px',
                  flexDirection: isRTL ? 'row-reverse' : 'row',
                  gap: isRTL ? '15px' : '0px',
                }}>
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  value={topSurfaceOpacity}
                  max={1}
                  min={0}
                  step={0.1}
                  onChange={handleChangeOpacityTopSurface}
                />
                <Typography className={classes.textSetting} sx={{ pl: 3, minWidth: '100px' }}>
                  {!topSurfaceOpacity ? t('trans.transparent') : `${t('trans.opacity')}: ${topSurfaceOpacity}`}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </div>
  );
};

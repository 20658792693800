import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Avatar, Box, Fade, IconButton, Menu, MenuItem, styled, Typography, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { MouseEvent, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { isValidToken } from 'services/auth';
import { logoutUser, queryMe } from 'services/clients/apiClient.services';

export const UserInfo = () => {
  const { isRTL } = useCheckRTL();
  const navigate = useNavigate();
  const isAuth = isValidToken();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      cursor: 'pointer',
    },
    avatarStyles: {
      justifyContent: 'center',
      p: 0,
      height: '40px',
      width: '40px',
      ...(isRTL ? { ml: 1 } : { mr: 1 }),
    },
    avatar: {
      borderRadius: '50%',
      border: `1px solid ${theme.palette.divider}`,
      // border: theme.palette.mode === 'dark' ? `1px solid ${theme.palette.divider}` : `1px solid ${theme.palette.divider}`,
    },
    info: {
      display: 'flex',
      height: '40px',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'start',
    },
  };

  const RoleUser = styled(Typography)({
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    // color: '#888687',
    color: theme.palette.mode === 'dark' ? '#fff' : '#888687',
  });

  const UserName = styled(Typography)({
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    // color: '#616161',
    color: theme.palette.mode === 'dark' ? '#fff' : '#616161',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: userData } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe(), {
    keepPreviousData: false,
    enabled: isAuth,
  });

  const userInfo = useMemo(() => {
    if (!userData) return;
    return {
      name: userData.data.name,
      email: userData.data.email,
      avatar: userData.data.avatar,
    };
  }, [userData]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Call Logout API
  const useLogoutUser = useMutation((sessionId: string) => logoutUser(sessionId || ''), {
    onSuccess: () => {
      navigate('/login');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });

  const handleLogout = () => {
    const sessionId = localStorage.getItem('sessionLogin');
    if (!sessionId) {
      navigate('/login');
      const theme = localStorage.getItem('theme');
      localStorage.clear();
      localStorage.setItem('theme', theme || 'light');
    } else {
      const theme = localStorage.getItem('theme');
      localStorage.clear();
      localStorage.setItem('theme', theme || 'light');
      useLogoutUser.mutate(sessionId);
    }
  };

  const handleOpenProfilePage = () => {
    navigate('/profile');
  };

  const open = Boolean(anchorEl);
  return (
    <Box
      sx={{
        ...styles.container,
      }}>
      <Box
        onClick={handleOpenProfilePage}
        sx={{
          display: 'flex',
        }}>
        <IconButton
          sx={{
            ...styles.avatarStyles,
          }}>
          <Avatar
            src={userInfo?.avatar}
            sx={{
              ...styles.avatar,
            }}
          />
        </IconButton>
        <Box sx={{ ...styles.info }}>
          <RoleUser noWrap>{userInfo?.email}</RoleUser>
          <UserName noWrap>{userInfo?.name}</UserName>
        </Box>
      </Box>
      <Box sx={{ ...styles.info }}>
        <IconButton aria-label="delete" onClick={handleClick}>
          <ArrowDropDownIcon sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#888687' }} />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}>
          <MenuItem onClick={handleOpenProfilePage}>My account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Typography } from '@mui/material';
import { RasterLayerDTO } from 'common/defines/analytic';
import { LayerTypeEnum, MapEnum } from 'common/defines/constants';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getRasterLayer } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { changeLinesVectorSensorId, changeStreamlineOrderSensorId } from 'store/slices/rightBarSlice';
import LinesVectorCollapse from './LinesVectorCollapse';
import StreamLineOrderCollapse from './StreamLineOrderCollapse';
import VectorContourCollapse from './VectorContourCollapse';

const VectorCollapse = () => {
  const [isShowVector, setIsShowVector] = useState<boolean>(false);
  const [isShowListVectorContour, setShowListVectorContour] = useState<boolean>(false);
  const [vectorContourSensorId, setVectorContourSensorId] = useState<string>('');

  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { dateAnalyticSelected, levelId } = useAppSelector(mapViewSelector);

  // hide list vector in the right bar if dateSelected is empty
  useEffect(() => {
    if (isEmpty(dateAnalyticSelected)) setShowListVectorContour(false);
  }, [dateAnalyticSelected]);

  useQuery(
    [QUERY_KEY.RASTER_LAYER, dateAnalyticSelected, levelId],
    () => getRasterLayer(levelId || '', dateAnalyticSelected || ''),
    {
      enabled: !isEmpty(levelId) && !isEmpty(dateAnalyticSelected),
      onSuccess: (res) => {
        const rasterList = res?.data as RasterLayerDTO[];
        if (Array.isArray(rasterList)) {
          const vectorContourItem = rasterList.find((item) => item.layerType === LayerTypeEnum.VECTOR_CONTOUR);
          if (vectorContourItem) setShowListVectorContour(true);
          else setShowListVectorContour(false);
          const vectorItemId = vectorContourItem?.analysisId?._id;
          if (vectorItemId) setVectorContourSensorId(vectorItemId);

          const streamlineOrderItem = rasterList.find((item) => item.layerType === LayerTypeEnum.STREAM_LINE_ORDER);
          const streamlineOrderItemId = streamlineOrderItem?.analysisId?._id;
          if (streamlineOrderItemId) {
            dispatch(changeStreamlineOrderSensorId(streamlineOrderItemId));
          } else {
            dispatch(changeStreamlineOrderSensorId(''));
          }

          const linesVectorItem = rasterList.find((item) => item.layerType === LayerTypeEnum.LINES);
          const linesVectorItemId = linesVectorItem?.analysisId?._id;
          if (linesVectorItemId) {
            dispatch(changeLinesVectorSensorId(linesVectorItemId));
          } else {
            dispatch(changeLinesVectorSensorId(''));
          }
        }
      },
    }
  );

  const { isRTL } = useCheckRTL();

  return (
    <>
      <Button
        startIcon={
          isShowVector ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsShowVector(!isShowVector)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{
            marginRight: isRTL ? 0 : 'auto',
            fontWeight: 'bold',
            fontSize: '13px !important',
            textAlign: isRTL ? 'right' : 'left',
            marginLeft: isRTL ? 'auto' : 0,
          }}>
          {t('trans.vector')}
        </Typography>
      </Button>
      {/* collap contour */}
      <VectorContourCollapse
        isShowVector={isShowVector}
        isShowListVectorContour={isShowListVectorContour}
        sensorId={vectorContourSensorId}
        mode={MapEnum.MAP_VIEW}
      />
      <StreamLineOrderCollapse isShowVector={isShowVector} mode={MapEnum.MAP_VIEW} />
      <LinesVectorCollapse isShowVector={isShowVector} />
    </>
  );
};

export default VectorCollapse;

import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { UserInfo } from 'components/Dashboard/UserInfo/UserInfo';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { breadCrumbsSelector, changeBreadScrumbsSelected } from 'store/slices/breadcrumbsSlice';
import SelectLanguage from './SelectLanguage';

interface IHeaderDashboard {
  onClick: () => void;
  open: boolean;
}

export const HeaderDashboard = ({ onClick, open }: IHeaderDashboard) => {
  const { isRTL } = useCheckRTL();
  const { clientId } = useParams();
  const location = useLocation();
  const { subBreadCrumbs } = useAppSelector(breadCrumbsSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isFirstSelectRole, setIsFirstSelectRole] = useState<string>(clientId ? 'Data Configuration' : 'Overview');
  const getMainBreadcrumbs = useMemo(() => {
    let newPath = location.pathname?.split('/')[1];
    return newPath && newPath.charAt(0).toUpperCase() + newPath.slice(1);
  }, [location.pathname]);

  useEffect(() => {
    setIsFirstSelectRole(subBreadCrumbs);
    dispatch(changeBreadScrumbsSelected(subBreadCrumbs));
  }, [dispatch, subBreadCrumbs]);

  return (
    <Box
      sx={{
        width: '100%',
        m: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          ...(isRTL ? { mr: open ? '240px' : '48px' } : { ml: open ? '240px' : '48px' }),
        }}>
        <IconButton component="span" onClick={() => onClick()}>
          {open ? <FormatIndentDecreaseIcon /> : <FormatIndentIncreaseIcon />}
        </IconButton>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            sx={{
              cursor: 'default',
            }}>
            {t(convertTextToMultiLanguage(getMainBreadcrumbs, 'trans'))}
          </Link>
          {subBreadCrumbs && (
            <Typography
              key="3"
              color="text.primary"
              sx={{
                cursor: 'default',
              }}>
              {t(convertTextToMultiLanguage(isFirstSelectRole, 'trans'))}
            </Typography>
          )}
        </Breadcrumbs>
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <SelectLanguage />
        <UserInfo />
      </Stack>
    </Box>
  );
};

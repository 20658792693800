import ReportIcon from '@mui/icons-material/Report';
import { Box, Grid, IconButton, InputLabel, TextField, Tooltip } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FILE_TYPE, mapFileLabel } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import SelectFile from 'components/Common/SelectFile';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createStandCount,
  deleteStandCountBoundaryData,
  getRasterDetails,
  getTreeIdTemplate,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { ErrorsDialog } from './ErrorDialog';

interface IStandCountForm {
  sensorId: any;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

export const StandCountForm: FC<IStandCountForm> = ({ sensorId, deleteFileGeojson }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { analyticId } = useAppSelector(mapViewSelector);

  const { data: analyticDetails, refetch } = useQuery([QUERY_KEY.RASTER_DETAILS, sensorId], () =>
    getRasterDetails(sensorId)
  );

  const { data: dataTreeIdTemplate, refetch: refetchGetTreeId } = useQuery(
    [QUERY_KEY.GET_TREEID_TEMPLATE, analyticId],
    () => getTreeIdTemplate(sensorId)
  );

  const createAnalyticMutation = useMutation((data: any) => createStandCount(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY);
      refetch();
      refetchGetTreeId();
      toast.success('Update Stand Count Analytic Successful', { toastId: 1 });
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message);
    },
  });

  useEffect(() => {
    formRef.current && formRef.current.resetForm();
  }, [sensorId, formRef]);

  const initialFields = useMemo(() => {
    let initParam = { file: undefined, treeIdTemplate: null, boundary: undefined };

    const template = dataTreeIdTemplate?.data?.template?.template;
    initParam.treeIdTemplate = template;

    if (analyticDetails?.data?.uploadHistory) {
      analyticDetails.data.uploadHistory.forEach((_item: any) => {
        if (_item.geoJsonType === 'STAND_COUNT_BOUNDARY') {
          initParam.boundary = _item;
        } else {
          initParam.file = _item;
        }
      });
    }
    return initParam;
  }, [analyticDetails, dataTreeIdTemplate?.data]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          formData.append('analysisId', sensorId);
          formData.append('fileData', values.file || '');
          formData.append('boundary', values.boundary);
          formData.append('treeIdTemplate', values?.treeIdTemplate || '');

          createAnalyticMutation.mutate(formData, {
            onSuccess: () => {
              resetForm();
            },
          });
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm, handleChange }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid display="flex" width="100%">
                <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                  <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                    <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                      {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.STAND_COUNT_FILE], 'file_name'))}
                    </InputLabel>
                    <SelectFile
                      analysisId={sensorId}
                      acceptFile={new RegExp(/.geojson$/)}
                      fileSelected={values.file}
                      disabled={createAnalyticMutation.isLoading}
                      onSelectFile={(file) => {
                        setFieldValue('file', file);
                      }}
                      fileType={FILE_TYPE.STAND_COUNT_FILE}
                      deleteFileGeojson={deleteFileGeojson}
                    />
                  </Grid>

                  <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                    <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                      {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.STAND_COUNT_CROP_BOUNDARY], 'file_name'))}
                    </InputLabel>
                    <SelectFile
                      analysisId={sensorId}
                      acceptFile={new RegExp(/.geojson$/)}
                      fileSelected={values.boundary}
                      disabled={createAnalyticMutation.isLoading}
                      onSelectFile={(file) => {
                        setFieldValue('boundary', file);
                      }}
                      fileType={FILE_TYPE.STAND_COUNT_CROP_BOUNDARY}
                      deleteFileGeojson={deleteStandCountBoundaryData}
                    />
                  </Grid>

                  {values.file && (
                    <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputLabel
                          variant="standard"
                          sx={{ fontSize: '14px', margin: 0 }}
                          htmlFor="uncontrolled-native">
                          {t('trans.label')}
                        </InputLabel>
                        <Tooltip title={t('trans.the_template_for_the_tree_ID')}>
                          <IconButton>
                            <ReportIcon sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Grid item xs={6}>
                        <TextField
                          type="text"
                          size="small"
                          name="treeIdTemplate"
                          onChange={handleChange}
                          value={values.treeIdTemplate}
                          sx={{
                            width: '100%',
                            mt: '7px',
                            '.MuiOutlinedInput-root': {
                              height: '40px',
                              justifyContent: 'end',
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid marginLeft={'auto'} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <ButtonCustom
                    title={t('trans.save')}
                    border="0px"
                    colorLabel="#FFF"
                    sx={{ width: '160px', height: '1.4375em' }}
                    backgroundColor="#23BE6A"
                    onClick={() => submitForm()}
                    disabled={createAnalyticMutation.isLoading}
                    loading={createAnalyticMutation.isLoading}
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};

import { useCheckRTL } from 'hooks/common/useCheckRLF';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';

const ScrollbarsCustom = (props: ScrollbarProps) => {
  const { isRTL } = useCheckRTL();
  return (
    <Scrollbars
      autoHide
      renderView={(props: any) =>
        isRTL ? (
          <div {...props} style={{ ...props.style, marginLeft: props.style.marginRight, marginRight: 0 }} />
        ) : (
          <div {...props} style={{ ...props.style }} />
        )
      }
      {...props}>
      {props.children}
    </Scrollbars>
  );
};

export default ScrollbarsCustom;

import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ComparedIndex, MapEnum, MapStateKey, TypeRaster } from 'common/defines/constants';
import { MapView } from 'components/MapView';
import ReactMapGLTiling from 'components/TilingMap/react-mapgl-tiling';
import { QUERY_KEY } from 'constants/constants';
import useQueryListTaskOfField from 'hooks/workspace/useQueryListTaskOfField';
import { SensorTypeEnum } from 'interfaces/workspace';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAllDateLandUseAnalytic } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeListDateInfo, IDateInfo, IRaster, mapPopupSelector } from 'store/slices/mapPopupSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface ComparedAnalyticProps {
  mode: MapEnum;
  comparedIndex: ComparedIndex;
}

const initialDateInfo = {
  date: '',
  raster: {
    description: '',
    name: TypeRaster.RGB_MULTI_SPECTRAL,
    _id: '',
  },
};

const ComparedAnalytic: FC<ComparedAnalyticProps> = ({ mode, comparedIndex }) => {
  const { t } = useTranslation();
  const [dateOptionList, setDateOptionList] = useState<IDateInfo[]>([]);
  const [selectedDateInfo, setSelectedDateInfo] = useState<IDateInfo>(initialDateInfo);

  const dispatch = useAppDispatch();
  const { levelId, dateAnalyticSelected } = useAppSelector(mapViewSelector);
  const { listDateInfo } = useAppSelector(mapPopupSelector);
  const { taskList } = useQueryListTaskOfField();

  const convertSensorTypeToTypeRaster = (name: SensorTypeEnum) => {
    switch (name) {
      case SensorTypeEnum.RGB:
        return TypeRaster.RGB;
      case SensorTypeEnum.RGB_MS:
      default:
        return TypeRaster.RGB_MULTI_SPECTRAL;
    }
  };

  useQuery([QUERY_KEY.DATE_HISTORY_LEVEL_M, levelId], () => getAllDateLandUseAnalytic(levelId!), {
    enabled: !!levelId && mode === MapEnum.MAP_VIEW,
    onSuccess(res) {
      const optionList: IDateInfo[] = res.data
        .map((item: { date: string; rasters: IRaster[] }) => ({
          date: moment(item.date).format('YYYY-MM-DD'),
          raster: item.rasters.find(
            (item) => item.name === TypeRaster.RGB_MULTI_SPECTRAL || item.name === TypeRaster.RGB
          ),
        }))
        .sort((a: { date: string }, b: { date: string }) => {
          return a.date < b.date ? 1 : -1;
        });
      setDateOptionList(optionList);
      if (optionList.length) {
        const initialDate = optionList.find((item) => item.date === dateAnalyticSelected);
        dispatch(changeListDateInfo([initialDate || optionList[0], optionList[0]]));
        if (comparedIndex === ComparedIndex.FIRST) setSelectedDateInfo(initialDate || optionList[0]);
        else if (comparedIndex === ComparedIndex.SECOND) setSelectedDateInfo(optionList[0]);
      }
    },
  });

  // handle taskList in crop intelligent mode
  useEffect(() => {
    if (taskList.length && mode === MapEnum.CROP_INTELLIGENT) {
      const optionList: IDateInfo[] = taskList.map((item) => {
        const { date, sensorType, _id, description } = item;
        return {
          date: moment(date).format('YYYY-MM-DD'),
          raster: {
            _id,
            description,
            name: convertSensorTypeToTypeRaster(sensorType),
          },
        };
      });

      setDateOptionList(optionList);
      if (optionList.length) {
        const initialDate = optionList.find((item) => item.date === dateAnalyticSelected);
        dispatch(changeListDateInfo([initialDate || optionList[0], optionList[0]]));
        if (comparedIndex === ComparedIndex.FIRST) setSelectedDateInfo(initialDate || optionList[0]);
        else if (comparedIndex === ComparedIndex.SECOND) setSelectedDateInfo(optionList[0]);
      }
    }
  }, [comparedIndex, dateAnalyticSelected, dispatch, mode, taskList]);

  const handleChangeDate = (event: SelectChangeEvent) => {
    const selectedDate = dateOptionList.find((item) => item.date === event.target.value);
    if (selectedDate) {
      setSelectedDateInfo(selectedDate);
      const newListDateInfo = listDateInfo.map((item, index) => (index + 1 === comparedIndex ? selectedDate : item));
      dispatch(changeListDateInfo(newListDateInfo));
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Grid sx={{ mb: '20px' }} container>
        <Grid item xs={4}>
          <Typography sx={{ mr: '8px', display: 'inline', fontWeight: 500 }}>Date: </Typography>
          <Select
            value={selectedDateInfo.date}
            onChange={handleChangeDate}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size="small">
            {dateOptionList.map((item, index) => (
              <MenuItem key={index} value={item.date}>
                {moment(item.date).format('DD-MM-YYYY')}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={8} sx={{ lineHeight: '32px' }}>
          <Typography sx={{ display: 'inline', fontWeight: 500 }}>Description: </Typography>
          <Typography sx={{ display: 'inline' }}>
            {selectedDateInfo?.raster?.description || t('trans.no_description')}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          '& .tool_map_icon': {
            display: 'none',
          },
          '& #minimap': {
            display: comparedIndex === ComparedIndex.SECOND ? 'none' : 'initial',
          },
          '& .minimap-box': {
            transform: 'scale(0.7)',
            left: '14px',
          },
          height: '760px',
          width: '100%',
        }}>
        {mode === MapEnum.MAP_VIEW && (
          <MapView
            navbarMapWidth={0}
            analyticPanelWidth={0}
            showAnalytics={false}
            openAnalytic={() => null}
            mapStateKey={MapStateKey.MAP_POPUP}
            comparedIndex={comparedIndex}
          />
        )}
        {mode === MapEnum.CROP_INTELLIGENT && (
          <ReactMapGLTiling mapStateKey={MapStateKey.MAP_POPUP} isShowNavbar={false} comparedIndex={comparedIndex} />
        )}
      </Box>
    </Box>
  );
};

export default ComparedAnalytic;

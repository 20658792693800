import ClientDashboard from 'components/ClientDashboard';
import ClientsForm from 'components/ClientsInfo/ClientsForm';
import MyProfilePage from 'components/Dashboard/UserInfo/MyProfilePage';
import ErrorsPage from 'components/ErrorsPage';
import SessionExpiredPage from 'components/ErrorsPage/SessionExpiredPage';
import PrivateRoute from 'components/PrivateRoute';
import TilingMap from 'components/TilingMap';
import AddEmailPage from 'pages/AddEmailPage';
import ChangePassword from 'pages/ChangePassword';
import DashboardPage from 'pages/DashboardPage';
import { DashboardAdminPage } from 'pages/DashboardPage/DashboardAdminPage';
import ForgotPassword from 'pages/ForgotPassword';
import { LandingAdminPage } from 'pages/LandingAdminPage';
// import LandingPage from 'pages/LandingPage';
import LoginPage from 'pages/LoginPage';
import { MapViewPage } from 'pages/MapViewPage';
import RequestInfomation from 'pages/RequestInfomation';
import SignupPage from 'pages/SignupPage';
import VerifyEmail from 'pages/VerifyEmailSuccess';
import { Navigate, Route, Routes } from 'react-router-dom';

export const Root = () => {
  return (
    <Routes>
      {/* <Route element={<LandingPage />} path="/" /> */}
      <Route
        element={
          <PrivateRoute>
            <LandingAdminPage />
          </PrivateRoute>
        }
        path="/landing"
      />
      <Route element={<LoginPage />} path="/" />
      <Route element={<LoginPage />} path="/login" />
      <Route element={<SignupPage />} path="/signup" />
      <Route element={<RequestInfomation />} path="/request-infomation" />
      <Route
        element={
          <PrivateRoute>
            <MyProfilePage />
          </PrivateRoute>
        }
        path="/profile"
      />
      <Route element={<AddEmailPage />} path="/add-email"></Route>
      <Route element={<ForgotPassword />} path="/forgot-password" />
      <Route element={<ChangePassword />} path="/change-password/:tokenId" />
      <Route element={<VerifyEmail />} path="/verify-email/:tokenId" />
      <Route
        element={
          <PrivateRoute>
            <DashboardAdminPage />
          </PrivateRoute>
        }
        path="/dashboard-admin"
      />
      <Route
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
        path="/dashboard/:clientId"
      />
      <Route
        element={
          <PrivateRoute>
            <ClientsForm />
          </PrivateRoute>
        }
        path="/create-clients"
      />
      <Route
        element={
          <PrivateRoute>
            <ClientsForm />
          </PrivateRoute>
        }
        path="/clients/edit/:clientId"
      />
      <Route
        element={
          <PrivateRoute>
            <ClientDashboard />
          </PrivateRoute>
        }
        path="/clients/dashboard/:clientId"
      />
      <Route
        element={
          <PrivateRoute>
            <TilingMap />
          </PrivateRoute>
        }
        path="/aero-insights/:workspaceId"
      />
      <Route
        element={
          <PrivateRoute>
            <ClientDashboard />
          </PrivateRoute>
        }
        path="/clients/dashboard-admin"
      />
      <Route
        element={
          <PrivateRoute>
            <SessionExpiredPage />
          </PrivateRoute>
        }
        path="/session-expired"
      />
      <Route element={<ErrorsPage status="401" />} path="/401" />
      <Route element={<ErrorsPage status="404" />} path="/404" />
      <Route element={<ErrorsPage status="waiting" />} path="/waiting-approval" />
      <Route
        element={
          <PrivateRoute>
            <MapViewPage />
          </PrivateRoute>
        }
        path="/map-view/:clientId"
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

import { Box, Grid, styled } from '@mui/material';
import { SliderCustom } from 'components/Common/SliderSelectDate';
import { ITask } from 'interfaces/workspace';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAnalyticName } from 'store/slices/rightBarSlice';
import { changeSelectedTaskId, tilingMapSelector } from 'store/slices/tilingMapSlice';

const GridSlider = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.color.white,
  overflow: 'hidden',
}));

interface TilingMapSliderSelectDateProps {
  taskList: ITask[];
}

const TilingMapSliderSelectDate: FC<TilingMapSliderSelectDateProps> = ({ taskList }) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>('');
  const dispatch = useAppDispatch();
  const { isGeneratingAnalysis } = useAppSelector(tilingMapSelector);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState<number>(0);

  const datePickers = taskList.map((item, index) => ({ value: index, label: moment(item.date).format('DD-MM-YYYY') }));

  useEffect(() => {
    dispatch(changeAnalyticName(null));
    const chosenTask = taskList.find((_, index) => index === selectedTaskIndex);
    const taskId = chosenTask?._id || '';
    setDescription(chosenTask?.description || t('trans.no_description'));
    dispatch(changeSelectedTaskId(taskId));
  }, [dispatch, selectedTaskIndex, taskList]);

  return (
    <GridSlider
      sx={{
        marginBottom: '8px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#202329' : theme.palette.background.default),
      }}>
      {datePickers.length > 0 && (
        <Scrollbars style={{ width: '100%', height: '90px' }} autoHide>
          <Box
            sx={{
              width: 'fit-content',
              height: '90px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
              paddingTop: '20px',
            }}>
            <SliderCustom
              sx={{ width: `${80 * (datePickers.length - 1)}px`, margin: '0px 48px' }}
              step={1}
              min={0}
              max={Math.max(datePickers.length - 1, 1)}
              marks={datePickers}
              value={selectedTaskIndex}
              onChange={(_, value) => {
                if (isGeneratingAnalysis) return;
                if (typeof value === 'number') {
                  setSelectedTaskIndex(value);
                }
              }}
              valueLabelDisplay="on"
              aria-label="custom thumb label"
              defaultValue={20}
              valueLabelFormat={() => description}
            />
          </Box>
        </Scrollbars>
      )}
    </GridSlider>
  );
};

export default TilingMapSliderSelectDate;

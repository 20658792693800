import { Box, FormControlLabel, Grid, Popover, Stack, Typography } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';

interface IPolygonLayer {
  id: string;
  label: string;
  visible: boolean;
  color: string;
  typeOfAnalytic: string;
  changeColorAnalyticsLayer: ActionCreatorWithPayload<{ id: string; color: string; typeOfAnalytic: string }, string>;
  changeVisibleAnalyticsLayer: ActionCreatorWithPayload<string, string>;
  dataPoints?: any[] | null;
  callback?: () => void;
}

export const PolygonLayer = ({
  changeColorAnalyticsLayer,
  changeVisibleAnalyticsLayer,
  typeOfAnalytic,
  id,
  label,
  visible,
  color,
  callback,
  dataPoints,
}: IPolygonLayer) => {
  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isRTL } = useCheckRTL();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const listAnalyticWithOutTranslationLabel = [
    TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS,
    TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN,
  ];

  const renderLabel = (label: string) => {
    if (listAnalyticWithOutTranslationLabel.includes(typeOfAnalytic as TYPE_ANALYTICS_MAP_VIEW)) {
      return label;
    } else {
      return t(convertTextToMultiLanguage(label, 'mapView'));
    }
  };

  return (
    <Grid item xs={12} md={12}>
      <FormControlLabel
        labelPlacement={isRTL ? 'start' : 'end'}
        control={<SwitchCustom checked={visible} onClick={() => dispatch(changeVisibleAnalyticsLayer(id))} />}
        label={
          <Stack
            direction={isRTL ? 'row-reverse' : 'row'}
            spacing={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {color && visible && (
              <Box
                sx={{
                  width: '14px',
                  height: '14px',
                  border: `1px solid ${color}`,
                  borderRadius: '100%',
                  backgroundColor: color,
                }}
                onClick={(e: any) => handleClick(e)}
              />
            )}
            <Typography className={classes.selectText}>{renderLabel(label)}</Typography>
          </Stack>
        }
      />
      <Popover
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          '.MuiPopover-paper': {
            overflowX: 'initial',
            overflowY: 'initial',
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 130,
          horizontal: 60,
        }}>
        <Box>
          <HexColorPickerDebounce
            style={{
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
            }}
            color={color}
            onChange={(_color: any) => {
              dispatch(
                changeColorAnalyticsLayer({
                  id,
                  color: _color,
                  typeOfAnalytic,
                })
              );
              callback && callback();
            }}
          />
        </Box>
      </Popover>
    </Grid>
  );
};

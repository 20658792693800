import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, styled, Typography, useTheme } from '@mui/material';
import { convertAnalyticNameToMultiLanguage } from 'common/utils/convert';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeDataTreeHeightInterval,
  changeVisibleIntervalTreeHeight,
  changeVisibleIntervalTreeHeightAllLayers,
  treeHeightAnalyticsSelector,
} from 'store/slices/map-view/treeHeightAnalytics';
import { changeLayer3D, mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import { QUERY_KEY } from '../../../../../../constants/constants';
import { updateInterval } from '../../../../../../services/analytics/apiAnalyticsConfig.services';
import { PolygonLayerTypeRange } from '../PolygonLayerTypeRange';
import SideSurface from '../SideSurface';
import { SwitchRaster } from '../SwitchRaster';
import TopSurface from '../TopSurface';

interface TreeHeightCardLayerProps {
  labelCard: string;
  isInTilingMap?: boolean;
}

export const TreeHeightCardLayer: FC<TreeHeightCardLayerProps> = ({ labelCard, isInTilingMap }) => {
  const [isShowRaster, setIsShowRaster] = useState<boolean>(false);
  const { isLayer3D, isDefaultInterval } = useAppSelector(mapViewSelector);

  const { isShowAllLayer, chartDataTreeHeightPreview, dataTreeHeightInterval } =
    useAppSelector(treeHeightAnalyticsSelector);

  const theme = useTheme();
  const { t } = useTranslation();

  const ButtonSubmit = styled(Button)((a) => {
    return {
      width: '100%',
      height: '40px',
      borderRadius: '5px',
      padding: '12px 10px',
      marginBottom: '30px',
      textTransform: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white',
      backgroundColor: `${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: '#1C9D57',
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#1C9D57',
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&:focus': {
        boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
        border: `1px solid ${theme.palette.primary.main}`,
      },
    };
  });

  const classes = useLayerSelectCardStyle();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [raster, setRaster] = useState(true);
  const [circumByRange, setCircumByRange] = useState(true);
  const [isChangeColor, setIsChangeColor] = useState(false);

  const [currentData, setCurrentData] = useState<any>(dataTreeHeightInterval);

  const treeHeightDisplay = dataTreeHeightInterval.range;

  useEffect(() => {
    setCurrentData(chartDataTreeHeightPreview ? chartDataTreeHeightPreview.intervalLimit : dataTreeHeightInterval);
  }, [chartDataTreeHeightPreview, dataTreeHeightInterval]);

  const handleUpdateIntervals = useMutation((data: any) => updateInterval(currentData._id, data), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([QUERY_KEY.CHARTS_TREE_HEIGHT_ANALYTIC, isDefaultInterval]).then(() => {});
    },
  });

  const handleChangeColors = (key: number, color: string) => {
    const newTreeHeightInterval = dataTreeHeightInterval.range.map((item) =>
      item.key === key ? { ...item, color } : item
    );
    dispatch(changeDataTreeHeightInterval({ range: newTreeHeightInterval }));
    setIsChangeColor(true);
  };

  const { analyticName }: { analyticName: any } = useAppSelector(rightBarSelector) || {};

  const { isRTL } = useCheckRTL();
  return (
    <>
      {treeHeightDisplay?.length > 0 && (
        <Grid sx={{ mt: 1 }}>
          <Grid item xs={12} md={12}>
            <Button
              startIcon={isShowRaster ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              onClick={() => setIsShowRaster(!isShowRaster)}
              classes={{ root: classes.buttonTextStyle }}>
              {t('trans.raster')}
            </Button>
            <Collapse in={isShowRaster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              <SwitchRaster analyticType={analyticName} isShowInlineBlock={true} />
            </Collapse>
          </Grid>

          <Button
            startIcon={raster ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setRaster(!raster)}
            classes={{ root: classes.buttonTextStyle }}>
            {t(convertAnalyticNameToMultiLanguage(labelCard))}
          </Button>
          <Collapse in={raster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <FormControlLabel
                  labelPlacement={isRTL ? 'start' : 'end'}
                  control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                  label={<Typography className={classes.selectText}>{isLayer3D ? '3D' : '2D'}</Typography>}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                }}></Grid>
            </Grid>
          </Collapse>
          <Grid item xs={12} md={12}>
            <Button
              startIcon={circumByRange ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              onClick={() => setCircumByRange(!circumByRange)}
              classes={{ root: classes.buttonTextStyle }}>
              {t('trans.categories')}
            </Button>
            <Collapse in={circumByRange} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              <FormControlLabel
                labelPlacement={isRTL ? 'start' : 'end'}
                control={
                  <SwitchCustom
                    checked={isShowAllLayer}
                    onClick={() => dispatch(changeVisibleIntervalTreeHeightAllLayers(!isShowAllLayer))}
                  />
                }
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography className={classes.selectText}>
                      {isShowAllLayer ? t('trans.unselect_all') : t('trans.select_all')}
                    </Typography>
                  </Box>
                }
              />

              {treeHeightDisplay?.length &&
                treeHeightDisplay.map((item) => {
                  return (
                    <PolygonLayerTypeRange
                      key={item.key}
                      label={item.lable}
                      color={item.color}
                      id={item.key}
                      index={item.key}
                      visible={item.visible}
                      changeColorAnalyticsLayer={handleChangeColors}
                      changeVisibleAnalyticsLayer={changeVisibleIntervalTreeHeight}
                      typeOfAnalytic={TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT}
                    />
                  );
                })}

              {isChangeColor && (
                <Grid container top={2} paddingTop={2} justifyContent={'center'} gap={2} xs={12}>
                  <Grid item xs={4}>
                    <Button
                      color="neutral"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderRadius: '5px',
                        p: '12px 10px',
                        mb: '30px',
                        textTransform: 'none',
                        border: `1px solid ${theme.palette.divider}`,
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                      }}
                      onClick={() => {
                        setIsChangeColor(false);
                        queryClient
                          .invalidateQueries([QUERY_KEY.CHARTS_TREE_HEIGHT_ANALYTIC, isDefaultInterval])
                          .then(() => {});
                      }}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <ButtonSubmit
                      onClick={() => {
                        setIsChangeColor(false);
                        handleUpdateIntervals.mutate(currentData.range);
                      }}>
                      Save
                    </ButtonSubmit>
                  </Grid>
                </Grid>
              )}
            </Collapse>
          </Grid>

          <Grid item xs={12} md={12}>
            {isLayer3D && <SideSurface />}
          </Grid>

          <TopSurface />
        </Grid>
      )}
    </>
  );
};
